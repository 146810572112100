/* eslint-disable react/jsx-one-expression-per-line */
import { Footer } from 'antd/lib/layout/layout';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaGlobe } from 'react-icons/fa';

import { useSettingsStore } from '@core/settings/store/settings';

const SOCIAL_LINKS_ENUM = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  LINKEDIN: 'LinkedIn',
  TWITTER: 'Twitter',
  WEBSITE: 'Website',
};

const HomeFooter = () => {
  const { clientLogo, clientLinks, isDayPassEnabled, isUnitsEnabled, isPackagesEnabled, isMeetingRoomEnabled } =
    useSettingsStore();
  const router = useRouter();
  const linkQuery = useMemo(() => ({ clientName: router.query.clientName }), [router.query.clientName]);
  const socialLinks = useMemo(
    () => [
      { label: SOCIAL_LINKS_ENUM.FACEBOOK, url: clientLinks.facebook, icon: FaFacebook },
      { label: SOCIAL_LINKS_ENUM.INSTAGRAM, url: clientLinks.instagram, icon: FaInstagram },
      { label: SOCIAL_LINKS_ENUM.TWITTER, url: clientLinks.twitter, icon: FaTwitter },
      { label: SOCIAL_LINKS_ENUM.WEBSITE, url: `https://${linkQuery.clientName}.pickspace.com/`, icon: FaGlobe },
    ],
    [clientLinks, linkQuery.clientName],
  );
  return (
    <Footer className="text-center bg-main text-white border-0 pb-7 px-16 xl:px-40 md:px-8 border-slate-200 w-full border-t-2 border-b-2 border-solid">
      <div className="flex flex-col md:flex-row w-full justify-between items-center">
        <div className="flex flex-col justify-center md:justify-start items-center md:items-start">
          <div className="relative w-[250px] h-[121px] mb-2">
            <Image style={{ objectFit: 'contain' }} src={clientLogo} alt="logo" fill />
          </div>
          <div className="flex flex-row items-center md:items-start">
            {socialLinks.map(({ label, url, icon: Icon }) => (
              <Link
                target="_blank"
                className="aspect-square w-[24px] items-center justify-center flex bg-[#00000050] rounded-full mr-3"
                key={label}
                href={url}
              >
                <Icon />
              </Link>
            ))}
          </div>
          <div className="mt-4 text-xl font-bold ">Download the app</div>
          <div className="flex flex-row mt-4 justify-center items-center md:items-start">
            <Link target="_blank" className="text-white no-underline mr-3" href={clientLinks.iosAppLink}>
              <Image alt="app-store-image" width={135} height={40} src="/home/appstore.svg" />
            </Link>
            <Link target="_blank" className="text-white no-underline" href={clientLinks.androidAppLink}>
              <Image alt="app-store-image" width={135} height={40} src="/home/googleplay.svg" />
            </Link>
          </div>
        </div>
        <div className="flex flex-row gap-4 md:gap-14 justify-around items-center md:items-start border-0 border-solid border-t-2 md:border-t-0 pt-6 pb-4 mt-4 border-[#ffffff50]">
          <div className="flex flex-col items-center md:items-start no-underline">
            {isUnitsEnabled && (
              <Link
                target="_blank"
                href={{
                  pathname: '/rentals',
                  query: linkQuery,
                }}
                className="text-lg md:text-xl my-2 no-underline"
              >
                Available Rentals
              </Link>
            )}
            {isMeetingRoomEnabled && (
              <Link
                target="_blank"
                href={{
                  pathname: '/booking/meeting-room',
                  query: linkQuery,
                }}
                className="text-lg md:text-xl my-2 no-underline"
              >
                Book Meeting Room
              </Link>
            )}
            {isDayPassEnabled && (
              <Link
                target="_blank"
                href={{
                  pathname: '/booking/day-pass',
                  query: linkQuery,
                }}
                className="text-lg md:text-xl my-2 no-underline"
              >
                Buy Day Pass
              </Link>
            )}
          </div>
          <div className="w-[2px] h-[120px] my-auto bg-[#ffffff50]" />
          <div className="flex flex-col items-center md:items-start no-underline">
            {isPackagesEnabled && (
              <Link
                target="_blank"
                href={{
                  pathname: '/booking/packages',
                  query: linkQuery,
                }}
                className="text-lg md:text-xl my-2 no-underline"
              >
                Buy Credits
              </Link>
            )}
            <Link
              target="_blank"
              href={`https://${router.query.clientName}.pickspace.com/#/login`}
              className="text-lg md:text-xl my-2 no-underline"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
      <div className="text-base md:text-lg flex flex-col md:flex-row mx-auto mt-4 pt-4 justify-center border-solid border-0 border-t-2 border-[#ffffff50] items-center">
        <div className="flex flex-col md:flex-row items-center">
          © All Rights Reserved 2016 - 2023 {new Date().getFullYear()}, Powered by
          <Link target="_blank" className="text-white underline ml-1" href="https://pickspace.com/">
            Pickspace Property Management Software.
          </Link>
        </div>
        <div className="relative w-[180px] h-[20px] md:w-[280px] mx-4 md:h-[30px]">
          <Image style={{ objectFit: 'contain' }} alt="pickspace-logo" src="/home/logowhite.png" fill />
        </div>
      </div>
    </Footer>
  );
};

export default HomeFooter;
